/*
 * preloder.less
 * -----------------------------------------------
*/
.spinner {
	left: none;
	top: none;
	height: none;
	width: none;
	img {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
	}
}