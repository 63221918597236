/*  
 * extra.less 
 * -----------------------------------------------
*/

/* -------- Heading Style ---------- */
.heading-border {
  border-bottom: 1px solid @gray-lightgray;
  margin: 30px 0;
  padding: 20px 0;
}

/* -------- FullCalendar - Plugin ---------- */
.fc-day-number {
  color: @black-333;
  
  &.fc-other-month {
    opacity: 0.4;
  }
}