/*
 * Shortcode: infinitescroll.less
 * -----------------------------------------------
*/
#infscr-loading {
	background-color: fade(@black, 07%);
	border-radius: 3px;
	color: #fff;
	font-size: 24px;
	height: 50px;
	line-height: 50px;
	left: 48%;
	position: fixed;
	text-align: center;
	top: 50%;
	width: 50px;
	z-index: 100;
	img {
		display: none;
	}
}