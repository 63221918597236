/*
 * Shortcode: team.less
 * -----------------------------------------------
*/
.attorney-address {
    li {
        background: @white-f8;
        margin-bottom: 15px;
        padding: 15px 20px;
        i {
            font-size: 15px;
            vertical-align: middle;
        }
    }
    .media-body {
         p {
          font-size: 12px;
          margin-bottom: 0;

          span {
                font-size: 16px;
                color: @black-555;
                margin-right: 5px;
                padding-bottom: 15px;
            }
        }
    }
}

.triangle {
    background: none repeat scroll 0 0 @white-base;
    height: 15px;
    left: -7px;
    position: absolute;
    top: 20%;
    .rotate(137deg);
    width: 15px;
}

.member-info {
  background: @gray-lighter none repeat scroll 0 0;
  padding: 20px;
}

.contact-area i {
  padding-right: 10px;
}
