    @charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Sports Trainer
Version:        1.0
Last change:    04.27.2016
Primary use:    SportsTrainer- Sports, Health, Gym & Fitness Personal Trainer Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Table of contents
@import "less-sportstrainer/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";


//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
@import "../css/stroke-gap-icons.css";
@import "css/flaticon-sports/flaticon-sports.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Pacifico);
@import url(https://fonts.googleapis.com/css?family=Playball);

 

 
// Initialize Variables
@import "less-sportstrainer/variables.less";
@import "less-sportstrainer/mixins.less";
// Typography
@import "less-sportstrainer/typography.less";

// Common Styles
@import "less-sportstrainer/common.less";
@import "less-sportstrainer/extra.less";
@import "less-sportstrainer/overlay.less";

// Header
@import "less-sportstrainer/header.less";

// Nav
@import "less-sportstrainer/nav.less";

// Content Blocks
@import "less-sportstrainer/topbar.less";
@import "less-sportstrainer/inner-header-title.less";
@import "less-sportstrainer/vertical-nav.less";
@import "less-sportstrainer/menu-full-page.less";
@import "less-sportstrainer/boxed-layout.less";
@import "less-sportstrainer/form.less";
@import "less-sportstrainer/side-push-panel.less";
@import "less-sportstrainer/box-hover-effect.less";
@import "less-sportstrainer/portfolio-gallery.less";
@import "less-sportstrainer/gallery-isotope.less";
@import "less-sportstrainer/sliders.less";
@import "less-sportstrainer/home.less";
@import "less-sportstrainer/about.less";
@import "less-sportstrainer/contact.less";
@import "less-sportstrainer/event.less";
@import "less-sportstrainer/services.less";
@import "less-sportstrainer/classes-details.less";
@import "less-sportstrainer/blog.less";
@import "less-sportstrainer/class-time.less";
@import "less-sportstrainer/funfact.less";
@import "less-sportstrainer/trainer.less";
@import "less-sportstrainer/traning-class.less";
@import "less-sportstrainer/calculator.less";
@import "less-sportstrainer/why-choose.less";
@import "less-sportstrainer/preloder.less";

// Shortcodes
@import "less-sportstrainer/shortcodes.less";


// Widgets
@import "less-sportstrainer/widgets.less";


// Footer
@import "less-sportstrainer/footer.less";