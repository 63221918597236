/*
 * contact.less
 * -----------------------------------------------
*/
.contact-form {
	.form-group {
		margin-bottom: 20px;
	}

	.form-control {
		background-image: none;
		border: 1px solid @gray-silver;
		border-radius: 0;
		box-shadow: none;
		height: 48px;

		&:focus,
		&:active {
			box-shadow: none;
			color: @black-333;
		}
	}

	.form-control2 {
		background-color: fade(@black, 80%);
		color: @gray-silver;
	}

	.form-group button {
		margin-top: 0;
	}
	textarea {
		&.form-control {
			height: 110px;
			resize: none;
		}
	}
}

textarea {
	resize: none;
}

.contact-icon-box {
	border: 1px solid #666;
	.contact-icon {
	    line-height: 50px;
	    text-align: center;
	    position: absolute;
	    top: -30px;
	    width: 50px;
	    height: 50px;
	}
} 