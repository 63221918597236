/*
 * Shortcode: testimonials.less
 * -----------------------------------------------
*/

.testimunial {
  background: url('../images/features-bg.jpg') repeat center top;
}
.carousel-control.left,
.carousel-control.right {
  background-image: none !important;
  filter: none;
}
.block-text {
  background-color: #fff;
  box-shadow: 0 1px 5px #e8e9ea;
  color: #626262;
  font-size: 14px;
  margin-top: 27px;
  padding-bottom: 1px;
}
.block-text a {
  color: #7d4702;
  font-size: 25px;
  font-weight: bold;
  line-height: 21px;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.mark {
  padding: 12px 0;background:none;
}
.block-text p {
  color: #585858;
  font-style: italic;
  line-height: 20px;
}
.sprite {
  border-left: 18px solid rgba(0, 0, 0, 0);
  border-top: 18px solid rgba(241,241,241,0.80);
  height: 0;
  left: -18px;
  position: absolute;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  width: 0;
}
.block-text ins {
  bottom: -18px;
  left: 0;
  margin: auto;
  right: 0;
}
.person-name {
  padding: 5px 0;
}
.block {
  display: block;
}
.zmin {
  z-index: 1;
}
.ab {
  position: absolute;
}
.person-text {
  padding: 24px 0 0;
  text-align: center;
  z-index: 2;
}
.person-text a {
  color: #ffcc00;
  display: block;
  font-size: 14px;
  margin-top: 3px;
  text-decoration: underline;
}
.person-text i {
  color: #fff;
  font-family: Georgia;
  font-size: 13px;
}
.rel {
  position: relative;
}

/*Style 2*/
.feedback {
  position: relative;
}

.big-triangle, .big-triangle-second-color {
  border-style: solid;
  border-width: 0 0 80px 80px;
  height: 0;
  position: absolute;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  width: 0;
  top: 0;
  left: 0;
}
.big-icon, .big-icon-second-color {
  border-radius: 50%;
  bottom: 7px;
  height: 49px;
  position: relative;
  right: 3px;
  text-align: center;
  width: 49px;
  z-index: 20;
  background: #fff;
}
.big-icon i.fa {
  line-height: 49px;
  color: #e6d8ad;
}