/*
 * Shortcode: flip-box.less
 * -----------------------------------------------
*/

.card {
	float: left;
	min-height: 280px;
	padding-bottom: 25%;
	position: relative;
	text-align: center;
	width: 100%;

	&:nth-child(1) {
		margin-left: -3px;
  		margin-right: 1px;
	}

	&:nth-child(1),.card:nth-child(3) {
  		margin-right: 1px;
	}

	.card__front,.card__back {
		position: absolute;
	  	top: 0;
	  	left: 0;
	  	width: 100%;
	  	height: 100%;
 		-webkit-backface-visibility: hidden;
         backface-visibility: hidden;
  		-webkit-transition: -webkit-transform 0.3s;
         transition: transform 0.3s;
	}

	.card__back {
	  	-webkit-transform: rotateY(-180deg);
	  	transform: rotateY(-180deg);
	}

	.card__text {
	  display: inline-block;
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  margin: auto;
	  line-height: 20px;
	}

	&.effect__hover {
		&:hover {
			.card__front {
	  			-webkit-transform: rotateY(-180deg);
	          			transform: rotateY(-180deg);
			}
			.card__back  {
				-webkit-transform: rotateY(0);
          				transform: rotateY(0);
			}
		}
	}

	&.effect__click {
		&.flipped {
			.card__front {
	  			-webkit-transform: rotateY(-180deg);
	          			transform: rotateY(-180deg);
			}
			.card__back  {
				-webkit-transform: rotateY(0);
          				transform: rotateY(0);
			}
		}
	}

	&.effect__random {
		&.flipped {
			.card__front {
	  			-webkit-transform: rotateY(-180deg);
	          			transform: rotateY(-180deg);
			}
			.card__back  {
				-webkit-transform: rotateY(0);
          				transform: rotateY(0);
			}
		}
	}

}


/*
 * Responsive : Flip Box
 * -----------------------------------------------
*/

@media only screen and  (max-width: 767px)  {
	.card {   
	 	margin-bottom: 30px;
    	min-height: 489px;
	}
}

@media only screen and (min-width: 501px) and (max-width: 600px)  {
	.card {   
	 	margin-bottom: 30px;
    	min-height: 400px;
	}
}

@media only screen and (min-width: 450px) and  (max-width: 500px)  {
	.card {   
	 	margin-bottom: 30px;
    	min-height: 350px;
	}
}

@media only screen and (min-width: 320px) and  (max-width: 449px)  {
	.card {   
	 	margin-bottom: 30px;
    	min-height: 300px;
	}
}